export function listenForLangSwitch() {
    const isJa = document.body.classList.contains("ja");
    const langSwitch = document.getElementById("lang-switch");
    langSwitch.addEventListener("click", () => {
        document.cookie = `firebase-language-override=${isJa ? "ja" : "en"}`;
        location.href = langSwitch.dataset.href;
    });
}

// via https://gist.github.com/gre/1650294
export const Ease = {
    // no easing, no acceleration
    linear: function (t) {
        return t;
    },
    // accelerating from zero velocity
    easeInQuad: function (t) {
        return t * t;
    },
    // decelerating to zero velocity
    easeOutQuad: function (t) {
        return t * (2 - t);
    },
    // acceleration until halfway, then deceleration
    easeInOutQuad: function (t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    // accelerating from zero velocity
    easeInCubic: function (t) {
        return t * t * t;
    },
    // decelerating to zero velocity
    easeOutCubic: function (t) {
        return --t * t * t + 1;
    },
    // acceleration until halfway, then deceleration
    easeInOutCubic: function (t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    // accelerating from zero velocity
    easeInQuart: function (t) {
        return t * t * t * t;
    },
    // decelerating to zero velocity
    easeOutQuart: function (t) {
        return 1 - --t * t * t * t;
    },
    // acceleration until halfway, then deceleration
    easeInOutQuart: function (t) {
        return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
    },
    // accelerating from zero velocity
    easeInQuint: function (t) {
        return t * t * t * t * t;
    },
    // decelerating to zero velocity
    easeOutQuint: function (t) {
        return 1 + --t * t * t * t * t;
    },
    // acceleration until halfway, then deceleration
    easeInOutQuint: function (t) {
        return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
    },
    easeOutSin: function (t) {
        return Math.sin((t * Math.PI) / 2);
    },
    easeInSin: function (t) {
        return 1 + Math.sin((Math.PI / 2) * t - Math.PI / 2);
    },
};

export function map(num, in_min, in_max, out_min, out_max) {
    return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

export function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
}

export function gradient(progress, handlers) {
    let lastUpTo = 0;
    for (const handler of handlers) {
        const [upTo, ease, lambda] = handler;
        if (progress <= upTo && lambda !== null) {
            const mapped = map(progress, lastUpTo, upTo, 0, 1);
            const t = ease !== null ? ease(mapped) : mapped;
            lambda(t);
            return;
        }
        lastUpTo = upTo;
    }
}

export function fadeInOut(
    progress,
    target,
    easeIn,
    easeOut,
    start,
    holdStart,
    holdEnd,
    end,
) {
    gradient(progress, [
        [
            start,
            null,
            (t) => {
                target(0);
            },
        ],
        [
            holdStart,
            easeIn,
            (t) => {
                target(t);
            },
        ],
        [
            holdEnd,
            null,
            (t) => {
                target(1);
            },
        ],
        [
            end,
            easeOut,
            (t) => {
                target(1 - t);
            },
        ],
        [
            Infinity,
            null,
            (t) => {
                target(0);
            },
        ],
    ]);
}
