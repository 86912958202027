import { Doorway, Model, GlImage, Camera } from "./modules/gl.js";
import {
    Ease,
    listenForLangSwitch,
    map,
    clamp,
    gradient,
    fadeInOut,
} from "./modules/util.js";

let mobileQuery = window.matchMedia("(max-width: 768px)");
let isMobile = mobileQuery.matches;
mobileQuery.addListener(() => {
    const nowIsMobile = mobileQuery.matches;
    const updateImages = nowIsMobile != isMobile;
    isMobile = nowIsMobile;
    if (updateImages) {
        lazyLoadVideos();
    }
});

listenForLangSwitch();

function initAspirationalVideo() {
    let aspirationalVideoPictures = document.querySelectorAll(
        "#aspirational-video picture",
    );

    // Cycle through the preview images.
    var aspirationalVideoPreviewFrameIndex = 0;
    let timer = setInterval(() => {
        aspirationalVideoPictures.forEach((e, index) => {
            if (index === aspirationalVideoPreviewFrameIndex) {
                e.classList.add("opaque");
            } else {
                e.classList.remove("opaque");
            }
        });
        aspirationalVideoPreviewFrameIndex += 1;
        aspirationalVideoPreviewFrameIndex %= aspirationalVideoPictures.length;
    }, 1500);

    let button = document.querySelector("#aspirational-video button");
    let video = document.querySelector("#aspirational-video video");
    let listener = button.addEventListener("click", () => {
        aspirationalVideoPictures.forEach((e) => {
            // Keep them there to avoid layout update.
            e.style.visibility = "hidden";
        });
        video.style.zIndex = 100;
        video.play();
        button.removeEventListener("click", listener);
        clearInterval(timer);
    });
}
initAspirationalVideo();

const stickyBackground = document.getElementById("sticky-background");
const logoWrapper = document.getElementById("portals-that-unify");
const mainHeading = document.getElementById("main-heading");
const heroVideo = document.getElementById("hero-video");
const shinkansen = document.getElementById("shinkansen-emoji");
const gen2Textured = document.getElementById("gen2-textured");
const gen2Xray = document.getElementById("gen2-xray");

// "progress" is defined as:
// 0.0 - the top of the section is at the very bottom of the screen
// 0.5 - the top of the section is halfway up the screen
// 1.0 - the top of the section is at the very top of the screen
// 1.5 - the top of the section is halfway above the top of the screen
// 2.0 - the section is completely above the screen, no longer visible
const TIMELINE = [
    {
        el: document.getElementById("portals-that-unify"),
        handler: (el, progress) => {
            // No expanding animation for mobile.
            const initialWidth = isMobile ? 0.8 : 0.68;
            const width = initialWidth + (1 - initialWidth) * clamp(progress, 0.0, 1.0);
            logoWrapper.style.width = `${100 * width}%`;
            stickyBackground.style.width = `${100 * width}%`;
            // Render it only after the initial dimensions are fixed.
            logoWrapper.style.visibility = "visible";
        },
    },
    {
        el: document.getElementById("human-connection"),
        handler: (el, progress) => {
            const fadeoutSpeedupFactor = 2;
            const opacity = 1 - clamp(progress * fadeoutSpeedupFactor, 0.0, 1.0);
            mainHeading.style["opacity"] = `${opacity}`;

            const logoSpeedupFactor = 1.4;
            const logoScaleProgress = lerp(1.0, 0.0, progress * logoSpeedupFactor);
            logoWrapper.style.setProperty(
                "--logo-scale-progress",
                `${logoScaleProgress}`,
            );

            heroVideo.style.visibility = progress > 0.99 ? "hidden" : "visible";
        },
    },
    {
        el: document.getElementById("shinkansen"),
        handler: (el, progress) => {
            let padding = (1.0 - progress) * 50;
            padding = clamp(padding, 0.0, 50.0);
            shinkansen.style["paddingLeft"] = `${padding}%`;
        },
    },
    {
        el: document.getElementById("gen2-render"),
        handler: (el, progress) => {
            const alpha = clamp(0.0, 1.0, (progress - (isMobile ? 0.7 : 0.9)) * 5);
            gen2Textured.style["opacity"] = `${1.0 - alpha}`;
            gen2Xray.style["opacity"] = `${alpha}`;
        },
    },
];

function lerp(a, b, t) {
    if (t <= 0) {
        return a;
    }
    if (t >= 1) {
        return b;
    }
    return a * (1 - t) + b * t;
}

function updateFillOverDarkElements() {
    const darkElements = document.querySelectorAll(".dark, .bg-black, .bg-blue");
    const darkRects = [...darkElements].map((e) => e.getBoundingClientRect());

    const logo = document.getElementById("logo");
    if (rectSetsOverlap(logo.getBoundingClientRect(), darkRects)) {
        logo.classList.add("lighten");
    } else {
        logo.classList.remove("lighten");
    }

    const langSwitch = document.getElementById("lang-switch");
    if (rectSetsOverlap(langSwitch.getBoundingClientRect(), darkRects)) {
        langSwitch.classList.add("lighten");
    } else {
        langSwitch.classList.remove("lighten");
    }
}

function rectSetsOverlap(rect1, rectSet2) {
    for (const rect2 of rectSet2) {
        if (rectsOverlap(rect1, rect2)) {
            return true;
        }
    }
    return false;
}

function rectsOverlap(rect1, rect2) {
    return (
        intervalsOverlap(rect1.top, rect1.bottom, rect2.top, rect2.bottom) &&
        intervalsOverlap(rect1.left, rect1.right, rect2.left, rect2.right)
    );
}

function intervalsOverlap(top1, bottom1, top2, bottom2) {
    return top1 <= bottom2 && bottom1 >= top2;
}

// Change a src URL such as "http://localhost:1234/vidjas/hero.m4v"
// to be "http://localhost:1234/vidjas/hero/mobile.m4v"
function responsiveMediaUrl(originalUrl) {
    let parts = originalUrl.split(".");

    const suffix = isMobile ? "/mobile" : "/desktop";
    parts[parts.length - 2] += suffix;

    return parts.join(".");
}

function lazyLoadVideo(video) {
    if (video.tagName === "VIDEO" && video.dataset.poster) {
        video.poster = responsiveMediaUrl(video.dataset.poster);
    }
    for (var source in video.children) {
        var videoSource = video.children[source];

        if (videoSource.tagName === "SOURCE") {
            videoSource.src = responsiveMediaUrl(videoSource.dataset.src);
        }
    }
    video.load();
}

function lazyLoadVideos() {
    document.querySelectorAll("video.lazy-loading").forEach(lazyLoadVideo);
}

lazyLoadVideos();

function fixHeroVideoClick() {
    // The hero video needs an explicit click to play on some mobile browsers with power-saving mode
    // turned on. However, the video's z-index is too deep to be clickable, and we need to rewire
    // events on #hero-video-section to the video to achieve this.
    document.getElementById("hero-video-section").onclick = function () {
        document.getElementById("hero-video").play();
    };
}

fixHeroVideoClick();

function computeScrollHeight() {
    return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight,
    );
}

function computeClientHeight() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

function computeYOffset() {
    return window.pageYOffset;
}

let cachedClientHeight = computeClientHeight();
let lastClientHeight = null;
let cachedYOffset = computeYOffset();
let lastScrollPercent = null;

window.addEventListener("resize", () => {
    // Update our cached variables.
    // Reference on what causes layout invalidation/reflow:
    // https://gist.github.com/paulirish/5d52fb081b3570c81e3a/565c05680b27c9cfd9f5e971d295cd558c3e1843
    cachedClientHeight = computeClientHeight();
    cachedYOffset = computeYOffset();
});

window.addEventListener("scroll", () => {
    cachedYOffset = computeYOffset();
});

function draw() {
    // stats.begin();
    // TODO(bschwind) - Cache scroll height if possible.
    let scrollHeight = computeScrollHeight();

    // Get the current visible height of the page
    let clientHeight = cachedClientHeight;

    // The amount we can actually scroll is the total scroll height
    // minus what we can see.
    let maxScroll = scrollHeight - clientHeight;

    // Get our scroll position and clamp it between 0 and the maximum
    // that we can scroll.
    let offset = cachedYOffset;
    offset = Math.min(maxScroll, Math.max(offset, 0));

    let scrollPercent = offset / maxScroll;

    if (lastScrollPercent !== scrollPercent || lastClientHeight !== clientHeight) {
        lastScrollPercent = scrollPercent;
        lastClientHeight = clientHeight;

        updateFillOverDarkElements();

        TIMELINE.forEach((step) => {
            const rect = step.el.getBoundingClientRect();

            let progress;
            // When the top of the step is below top of the viewport
            // compute progress based on how high within the viewport the
            // top of the element is so that when progress is
            // < 0 -> outside viewport
            // 0   -> at the bottom of the viewport
            // 0.5 -> at the middle of the viewport
            // 1   -> at the top of the viewport
            if (rect.y >= 0) {
                progress = 1 - rect.y / clientHeight;
            }
            // Once the top of the element passes the top of the viewport
            // compute the progress based on how much of the element is still visible
            // so that when progress is
            // 1   -> it's still entirely visible (technically) handled by the prior if-branch
            // 0.5 -> half of the element is visible
            // 2   -> it's completely above the viewport
            else {
                progress = 1 + -rect.y / rect.height;
            }

            step.handler(step.el, progress);
        });
    }

    // stats.end();
    window.requestAnimationFrame(draw);
}

window.requestAnimationFrame(draw);

function initEmailForm() {
    document.querySelector("#email-form").addEventListener("submit", (event) => {
        var target = event.target;
        fetch(target.getAttribute("action"), {
            method: target.getAttribute("method"),
            body: new FormData(target),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.success) {
                    target.style.display = "none";
                    document
                        .querySelector("#email-form-success")
                        .classList.remove("hidden");
                }
            });
        event.preventDefault();
    });
}
initEmailForm();
